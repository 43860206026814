// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-construction-js": () => import("./../src/pages/en-construction.js" /* webpackChunkName: "component---src-pages-en-construction-js" */),
  "component---src-templates-pages-custom-page-js": () => import("./../src/templates/pages/customPage.js" /* webpackChunkName: "component---src-templates-pages-custom-page-js" */),
  "component---src-templates-pages-intro-page-js": () => import("./../src/templates/pages/introPage.js" /* webpackChunkName: "component---src-templates-pages-intro-page-js" */)
}

