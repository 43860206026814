import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import theme from "configs/theme"
import Layout from "antd/es/layout"
import "antd/dist/antd.css"

import {
  GlobalStyle,
  GlobalStyleAntd,
  GlobalStyleUtils,
  TextUtilStyles,
  GlobalResponsive,
} from "./styles"

export const Container = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Layout>
      <GlobalStyle />
      <GlobalResponsive />
      <GlobalStyleAntd />
      <GlobalStyleUtils />
      <TextUtilStyles />
      {children}
    </Layout>
  </ThemeProvider>
)

Container.propTypes = {
  children: PropTypes.any,
}

Container.defaultProps = {}

export default Container
