import styled, { createGlobalStyle } from "styled-components"
import Layout from "antd/lib/layout"
import Taputapuatea from "fonts/Taputapuatea-Regular.ttf"
import Miri from "fonts/RMMIRI.ttf"
import Pua from "fonts/RMPUA.ttf"
import Taina from "fonts/RMTAINA.ttf"
import Tiare from "fonts/RMTIARE.ttf"

export const GlobalStyle = createGlobalStyle`
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px !important;
    font-weight: 600 !important;
  }
  
  h1 {
    font-size: 28px;
    line-height: 32px;
  }
  
  h2 {
    font-size: 26px;
    line-height: 30px;
  }
  
  h3 {
    font-size: 24px;
    line-height: 28px;
  }
  
  h4 {
    font-size: 22px;
    line-height: 26px;
  }
  
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
  
  h6 {
    font-size: 20px;
    line-height: 24px;
  }
  
  p {
    font-size: 20px;
    line-height: 24px;
    overflow-wrap: break-word;
    margin-bottom: 10px !important;
  }
  
  ul {
    margin: 0 !important;
  }
  
  .homepage {
    padding: 0 !important;
  }
  
  .component {
    margin-bottom: 25px;
    
    &:last-child {
       margin-bottom: 0;
    }
  }

  @font-face {
    font-family: 'Tapu';
    src: url(${Taputapuatea}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Miri';
    src: url(${Miri}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Pua';
    src: url(${Pua}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Taina';
    src: url(${Taina}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Tiare';
    src: url(${Tiare}) format('truetype');
    font-weight: 300;
  }
  
  .ant-tabs-tabpane {
    > .component {
      padding-top: 0;
    }
    
    .component {
      padding-bottom: 20px;
    }
  }
  
  .ant-select-dropdown {
    img {
     width: 20px;
     height: 20px;
    }
  }

  .sidebar-header {
    background-color: ${({ theme }) => theme.primaryColor} !important;
  }
`

export const GlobalStyleAntd = createGlobalStyle`
  .ant-layout {
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.color.white};
  }
`

export const GlobalStyleUtils = createGlobalStyle`
   .ant-form {
      .ant-form-item {
        margin-right: 0;
      }

      .ant-btn {
        margin: 0;
      }
      
      .ant-input {
        border-radius: 0;
        
        &:hover {
          border-color: ${({ theme }) => theme.primaryColor};
        }

        &:focus {
          border-color: ${({ theme }) => theme.primaryColor};
          box-shadow: 0 0 0 2px rgba(240, 183, 42, 0.4);
        }
      }
     
      input.ant-input {
         height: 50px;
      }
      
      textarea.ant-input {
        min-height: 200px;
      }
      
   }
    
  .img-fluid {
    max-width: 100%;
  }

  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }   
   
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
      display: block;
      content: "";
    }

    .embed-responsive-item,
    iframe,
    embed,
    object,
    video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .embed-responsive-21by9 {
    &::before {
      padding-top: percentage(9 / 21);
    }
  }

  .embed-responsive-16by9 {
    &::before {
      padding-top: percentage(9 / 16);
    }
  }

  .embed-responsive-4by3 {
    &::before {
      padding-top: percentage(3 / 4);
    }
  }

  .embed-responsive-1by1 {
    &::before {
      padding-top: percentage(1 / 1);
    }
  }

  .menu-mobile {
    .sidebar-header, .first-back-btn {
      line-height: 1.5;
      height: 80px;
    }
    
    .first-back-btn {
      line-height: 36px;
      display: flex;
      align-items: center;

      span {
        font-size: 20px; 
      }

      svg {
        fill: #898989;
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
    

    .sidebar-body {
      span {
        line-height: 1.5;
      }

      .second-back-btn {
        line-height: 1.5;
        svg {
          fill: #898989;
        }
      }
    }
  }
`

export const StyledContent = styled(Layout.Content)`
  padding: 50px 0;
`

export const StyledPageReference = styled.div`
  padding-bottom: 50px;
`

export const StyledHeader = styled(Layout.Header)`
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  height: 80px;
  padding: 0;
  background-color: ${({ theme }) => theme.primaryColor};
  width: 100%;
  box-shadow: 0 1px 20px rgba(000, 000, 000, 0.3);  
`

export const StyledFooter = styled(Layout.Footer)`
  padding: 0;
  background-color: ${({ theme }) => theme.color.white};

  .footer {
    padding: 20px;
  }

  .contactFooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const StyledCopyright = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`

export const TextUtilStyles = createGlobalStyle`
  .uppercase {
    text-transform: uppercase;
  }
`

export const GlobalResponsive = createGlobalStyle`
  html {
      font-size: 18px; 
  }

  /*  Small tablets to big tablet: 768 to 1023px */
  @media (max-width: 1023px) {
    html {
      font-size: 16px;
    }
  }

  /* Small phones to small tablets : 481 to 767px */
  @media (max-width: 767px) {
    html {
      font-size: 14px;
    }
  }

  /* Small phones : 0 to 480px */
  @media (max-width: 480px) {
    html {
      font-size: 14px;
    }
  }
  
  .text-align-justify {
    text-align: justify;
  }
  
  .text-align-left {
    text-align: left;
  }
  
  .text-align-right {
    text-align: right;
  }
`
