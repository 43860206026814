module.exports = {
  primaryColor: "#F0B72A",
  secondaryColor: "#a37400",
  buttonColor: "#F0B72A",
  color: {
    white: "#FFF",
    black: "#000",
    yellow: '#F0B72A'
  },
  fontFamily: {
    bigTitle: "bello-caps, sans-serif",
    main: "Source Sans Pro, sans-serif",
    second: "Muli, sans-serif",
  },
  slider: {

  },
  title: {
    fontSizeTitle: "45px",
    lineHeightTitle: "50px",
  },
  fontSize: {
    bigTitle: "7rem" /* 140px */,
    subtitle: "4rem" /* 80px */,
    title: "2rem",
    header: "3rem" /* 60px */,
    sectionTitle: "1.75rem" /* 35px */,
    tabsTitle: "1.1rem" /* 22px */,
    menuTitle: "1.15rem" /* 23px */,
    button: "1rem" /* 20px */,
    paragraph: "1rem" /* 20px */,
  },
  lineHeight: {
    bigTitle: "7.5rem" /* 150px */,
    subtitle: "4.5rem" /* 90px */,
    header: "3.15rem" /* 63px */,
    sectionTitle: "1.9rem" /* 38px */,
    tabsTitle: "1.25rem" /* 25px */,
    menuTitle: "1.3rem" /* 26px */,
    button: "1.2rem" /* 20px */,
    paragraph: "1.2rem" /* 21px */,
  },
}
